<template>
  <div class="PersonalInfoH5" :style="'height:' + Height + 'px'">
    <div class="user-header">
      <img
        style="width: 100%"
        src="https://wap.datafountain.cn/__assets__/img/user-background.67496ad8.png"
        alt=""
      />
      <div class="user-self">
        <div class="img-container">
          <div
            class="user-header-img"
            :style="
              'background-image: url(' + update_info.student_card_path + ');'
            "
          ></div>
        </div>
        <div class="user-text">
          <div class="user-name">{{ update_info.nickname }}</div>
          <div class="user-id">{{ update_info.student_num }}</div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="user-info">
        <div class="user-info__item" @click="Jump('/Info')">
          <div class="user-info__left">
            <img src="../../assets/icon/MyInfo.png" alt="" />
            <div class="user-info__text">个人资料</div>
          </div>
          <img
            style="width: 10px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAABFklEQVQ4T6XSsW6DMBAG4N/yYikd0ldpu7XNUClLpA4sPFKHPggIFhaahCaPkLFvUYEqGGDAXHVWQSkBYVpPtuVP/+nOAgB8378D8KKUch3H+eK7qSUYEdEBwFIIcVJKrW0ww3ciWrcJtlhEUbSsqupARFyuWTZY8MO/YANbXJblEcCtTXIH+XEcx9d5nnOjJvEvOAdfwBYXRXEkopuxsgehDR6FYRheaa33RHR/lvohpXxyXfdzEDKq63oH4GEImVn3/6QNuoA/5W2J6HEsqbtvN0mSLLIs29mgLnEuMpBRmqZbAKup8s77ITzPewOwmYNMYhAEK601Jy6EEN2c+t3un804GDdN8yqlfObhTqHBOdqgf8FvEH/EF82AfGYAAAAASUVORK5CYII="
            alt=""
          />
        </div>
        <!-- <div class="user-info__item">
          <div class="user-info__left">
            <img src="../../assets/icon/update.png" alt="" />
            <div class="user-info__text">上次比赛源码</div>
          </div>
          <img
            style="width: 10px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAABFklEQVQ4T6XSsW6DMBAG4N/yYikd0ldpu7XNUClLpA4sPFKHPggIFhaahCaPkLFvUYEqGGDAXHVWQSkBYVpPtuVP/+nOAgB8378D8KKUch3H+eK7qSUYEdEBwFIIcVJKrW0ww3ciWrcJtlhEUbSsqupARFyuWTZY8MO/YANbXJblEcCtTXIH+XEcx9d5nnOjJvEvOAdfwBYXRXEkopuxsgehDR6FYRheaa33RHR/lvohpXxyXfdzEDKq63oH4GEImVn3/6QNuoA/5W2J6HEsqbtvN0mSLLIs29mgLnEuMpBRmqZbAKup8s77ITzPewOwmYNMYhAEK601Jy6EEN2c+t3un804GDdN8yqlfObhTqHBOdqgf8FvEH/EF82AfGYAAAAASUVORK5CYII="
            alt=""
          />
        </div> -->
        <div class="user-info__item" @click="Jump('/MyGame')">
          <div class="user-info__left">
            <img src="../../assets/icon/Competi.png" alt="" />
            <div class="user-info__text">我的比赛</div>
          </div>
          <img
            style="width: 10px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAABFklEQVQ4T6XSsW6DMBAG4N/yYikd0ldpu7XNUClLpA4sPFKHPggIFhaahCaPkLFvUYEqGGDAXHVWQSkBYVpPtuVP/+nOAgB8378D8KKUch3H+eK7qSUYEdEBwFIIcVJKrW0ww3ciWrcJtlhEUbSsqupARFyuWTZY8MO/YANbXJblEcCtTXIH+XEcx9d5nnOjJvEvOAdfwBYXRXEkopuxsgehDR6FYRheaa33RHR/lvohpXxyXfdzEDKq63oH4GEImVn3/6QNuoA/5W2J6HEsqbtvN0mSLLIs29mgLnEuMpBRmqZbAKup8s77ITzPewOwmYNMYhAEK601Jy6EEN2c+t3un804GDdN8yqlfObhTqHBOdqgf8FvEH/EF82AfGYAAAAASUVORK5CYII="
            alt=""
          />
        </div>
      </div>
      <div @click="LogOut" class="log-out">退出登录</div>
    </div>
    <Tabbar :selected="selected"></Tabbar>
  </div>
</template>

<script>
import Tabbar from "../../components/H5/Tabbar.vue";
import { Dialog } from "vant";
import { Notify } from "vant";
import {
  change_password,
  update_user,
  getInfo,
  entry_list,
  MatchList,
  upload_code,
} from "../../api/ThisInfo.js";
import { getToken } from "@/utils/auth";
export default {
  components: { Tabbar },
  data() {
    return {
      selected: "PersonalInfoH5",
      Height: document.documentElement.clientHeight,
      update_info: {},
    };
  },
  created() {
    this.GetgetInfo();
  },
  methods: {
    GetgetInfo() {
      getInfo().then((res) => {
        this.update_info = res.data;
      });
    },
    LogOut() {
      Dialog.confirm({
        title: "提示",
        message: "是否退出登录？",
      })
        .then(() => {
          // on confirm
		  this.$store
            .dispatch("FedLogOut", null)
            .then(() => {
              this.$router.push({
                path: this.redirect || "/",
              });
			   Notify({ type: "success", message: "退出登录成功" });
            })
        })
        .catch(() => {
          // on cancel
        });
    },
	Jump(URl){
		this.$router.push(URl);
	}
  },
};
</script>

<style lang="scss" scoped>
.PersonalInfoH5 {
  width: 100%;
  .user-header {
    position: relative;
    width: 100%;
    // height: 400px;
    img{
      height: 20rem;
    }
    .user-self {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 122px;
      padding: 40px 20px 0;
      display: flex;
      align-items: center;
      .img-container {
        height: 76px;
        width: 76px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        box-shadow: 0 0.10667rem 0.18667rem 0 #1235c2;
        overflow: hidden;
        .user-header-img {
          position: relative;
          display: block;
          height: 76px;
          width: 76px;
          border-radius: 50%;
          background-position: 50%;
          background-size: cover;
        }
      }
      .user-text {
        position: relative;
        top: 0.10667rem;
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .user-name {
          padding-bottom: 0.21333rem;
          font-size: 1.2rem;
          font-family: DOUYU-Font, DOUYU;
          font-weight: 400;
          color: #fff;
          line-height: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .user-id {
          margin-top: 10px;
          font-size: 0.9rem;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #fff;
          line-height: 1rem;
        }
      }
    }
  }
  .content-body {
    width: calc(100% - 20px);
    // height: 313px;
    margin: 0 auto;
    position: relative;
    top: -180px;
    .user-info {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 10px 20px;
      .user-info__item {
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-info__left {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            height: 25px;
          }
          .user-info__text {
            font-size: 1rem;
            padding-left: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #585e66;
          }
        }
      }
    }
    .log-out {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      margin-top: 20px;
      background: #fff;
      border-radius: 0.8rem;
      font-size: 1rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8b9096;
    }
  }
}
</style>